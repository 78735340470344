<template>
  <div class="winners page-block position-relative" id="winners-block" :class="promotionIsAfter ? '':'winners--bg'">
    <div class="container-xl">
      <div class="row">
        <div class="col-12 ">
          <div class="winners-block__inner">
            <h2>{{tr('winners-title')}}</h2>
            <!-- TAB -->
            <div class="winners-button-select d-none d-md-block" >
              <button 
                :class="{'active':winnerState === 'daily'}" 
                v-on:click="setWinnerState('daily');setGTM('nyertesek_daily')"
              >
                {{tr('winners-tab-1')}}
              </button> 
              <button class="my-3 my-lg-0"
                :class="{'active':winnerState === 'weekly'}" 
                v-on:click="setWinnerState('weekly');setGTM('nyertesek_weekly')"
              >
                {{tr('winners-tab-2')}}
              </button>              
              <button 
                :class="{'active':winnerState === 'main'}" 
                v-on:click="setWinnerState('main');setGTM('nyertesek_main')"
              >{{tr('winners-tab-3')}}
              </button>
            </div>
            <div class="winners-button-select d-block d-md-none" >
              <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle mx-0" type="button" data-toggle="dropdown" aria-expanded="false">
                    <span v-if="winnerState == 'daily'">{{tr('winners-tab-1')}}</span>
                    <span v-else-if="winnerState == 'weekly'">{{tr('winners-tab-2')}}</span>
                    <span v-else>{{tr('winners-tab-3')}}</span>
                </button>
                 <ul class="dropdown-menu">
                    <li><button class="mx-0 text-primary" 
                  v-on:click="setWinnerState('daily');setGTM('nyertesek_daily')" href="#">{{tr('winners-tab-1')}}</button></li>
                    <li><button class="my-2 mx-0 text-primary" 
                  v-on:click="setWinnerState('weekly');setGTM('nyertesek_weekly')" href="#">{{tr('winners-tab-2')}}</button></li>
                    <li><button class="mx-0 text-primary" 
                  v-on:click="setWinnerState('main');setGTM('nyertesek_main')" href="#">{{tr('winners-tab-3')}}</button></li>
                </ul>
              </div>
            </div>
       

            <!-- TABLE -->
            <div class="winners-wrap">
              <div class="winners__item">
                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block "  >
                  <thead>
                    <tr class="winners-top">
                      <th>{{tr('winners-table-th-1')}}</th>
                      <th>{{tr('winners-table-th-2')}}</th>
                      <th>{{tr('winners-table-th-3')}}</th>
                    </tr>
                  </thead>
                  <tbody v-if="filteredWinners && filteredWinners.length">

                      <tr class="winners-list" v-for="(winner, winnerindex) in filteredWinners" :key="winnerindex">
                        <td class="winners-id">
                          <div><strong>{{ winner.name }}</strong></div> 
                          <div class="d-md-none">{{winnings[winnerState]}}</div>
                        </td>
                        <td v-if="winner.type == 'daily'" class="winner-type-td d-none d-md-table-cell ">                          
                          {{winnings.daily}}
                        </td>
                        <td v-if="winner.type == 'weekly'" class="winner-type-td d-none d-md-table-cell ">                          
                          {{winnings.weekly}}
                        </td>
                        <td v-if="winner.type == 'main'" class="winner-type-td d-none d-md-table-cell ">                          
                          {{winnings.main}}
                        </td>
                        <td class="winners-product text-right text-md-left">
                         <span>{{winner.winning_time}}</span>
                        </td>

                      </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td v-if="winnerState == 'daily'" class="no-winner" colspan="4" style="color:#461100;">
                        {{tr("winners-empty-table-daily")}}  
                      </td>
                      <td v-if="winnerState == 'weekly'" class="no-winner" colspan="4" style="color:#461100;">
                        {{tr("winners-empty-table-weekly")}}
                      </td>
                      <td v-if="winnerState == 'main'" class="no-winner" colspan="4" style="color:#461100;">
                        {{tr("winners-empty-table-main")}}  
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-8 offset-lg-2">
          <p class="winners-more-text">{{tr("winners-info-text")}}  </p>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
import promoStatus from "@/config/promoStatus";

export default {
  data() {
    return {
      winnerState: "daily",
      winnerTypes: {
        napi: "Napi nyertes",
        weekly: "weekly nyertes",
        main: 'Fődíj nyertes'
      },
      winnings:{
        daily: this.tr('winning-type-daily'),
        weekly: this.tr('winning-type-weekly'),
        main: this.tr('winning-type-main')
      }
    }
  },
  computed: {

    promotionIsActive() {
      return this.$store.state.promoStatus === promoStatus.live;
    },
    promotionIsBefore() {
      return this.$store.state.promoStatus === promoStatus.before;
    },
    promotionIsAfter() {
      return this.$store.state.promoStatus === promoStatus.after;
    },
    filteredWinners() {
      //return this.$store.state.winnersAll;
      return (this.$store.state.winnersAll ?? []).filter(w => w.type === this.winnerState)
    }
  },
  created() {
    this.$store.dispatch("getWinners");    
  },
  components: {},
  methods: {
    setGTM(item){

      this.GTtrackGA4({
        'event' : 'event',
        'category': 'nyertesek',
        'action': 'kattintas',
        'label': item,
        'clicked_text':item,
        'action_type': 'nyertesek_kattintas'
      })
    },
    setWinnerState(newState) {
      this.winnerState = newState;      
    },
  }
}
</script>